<template>
  <div class="w-full h-full">
    <router-view>
      
    </router-view>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>